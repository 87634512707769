import countries from '../enums/countries'

export const getIndividualServicePageUrl: (
  type: string,
  slug: string
) => string = (type = '', slug = '') => {
  switch (type) {
    case 'text':
      return `/writing-services/${slug}/`
    case 'seo':
      return `/seo-writing-services/${slug}/`
    case 'demandGen':
      return `/demand-generation-writing-services/${slug}/`
    case 'design':
      return `/design-services/${slug}/`
    case 'vernacular':
      return `/language-services/${slug}/`
    case 'video':
      return `/video-services/${slug}/`
    case 'language':
      return `/languages/${slug}/`
    case 'industries':
      return `/industries/${slug}/`
    default:
      return `/services/${slug}/`
  }
}

export const getIndividualServiceSlugType: (
  slug: string
) => StrapiServiceCategory | null = (slug = '') => {
  switch (slug) {
    case 'writing-services':
      return `text`
    case 'seo-writing-services':
      return `seo`
    case 'demand-generation-writing-services':
      return `demandGen`
    case 'design-services':
      return `design`
    case 'language-services':
      return `vernacular`
    case 'video-services':
      return `video`
    default:
      return null
  }
}

export const getIndividualServiceTypeTitle: (type: string) => {
  title: string
  url: string
} = (type = '') => {
  switch (type) {
    case 'text':
      return {
        title: `Writing Services`,
        url: `https://www.peppercontent.io/writing-services`
      }
    case 'seo':
      return {
        title: `SEO Writing Services`,
        url: `https://www.peppercontent.io/seo-writing-services`
      }
    case 'demandGen':
      return {
        title: `Demand Generation Writing Services`,
        url: `https://www.peppercontent.io/demand-generation-writing-services`
      }
    case 'design':
      return {
        title: `Design Services`,
        url: `https://www.peppercontent.io/design-services`
      }
    case 'vernacular':
    case 'language':
      return {
        title: `Language Services`,
        url: `https://www.peppercontent.io/language-services`
      }
    case 'video':
      return {
        title: `Video Services`,
        url: `https://www.peppercontent.io/video-services`
      }
    case 'language':
      return {
        title: `Languages`,
        url: `https://www.peppercontent.io/languages`
      }
    default:
      return { title: `Services`, url: `https://www.peppercontent.io/services` }
  }
}

export const getCookie = (name = ''): string | undefined => {
  let cookies: string[] = []
  const cookieStore: Record<string, string> = {}

  cookies = document.cookie.split(';')
  if (cookies[0] === '' && cookies[0][0] === undefined) {
    return undefined
  }

  cookies.forEach(cookie => {
    const temp = cookie.split(/=(.+)/)
    if (temp[0].substr(0, 1) === ' ') {
      temp[0] = temp[0].substr(1)
    }
    cookieStore[temp[0]] = temp[1]
  })

  return cookieStore[name]
}

export const getUtmParametersFromCookie = (): Nullable<UTMParams> => {
  const defaultValue: Nullable<UTMParams> = {
    utm_source: null,
    utm_medium: null,
    utm_campaign: null
  }

  const campaignUrl = getCookie('__gtm_campaign_url')
  const decodedCampaignUrl = campaignUrl
    ? decodeURIComponent(campaignUrl)
    : null

  if (!decodedCampaignUrl) return defaultValue

  try {
    const url = new URL(decodedCampaignUrl ?? '')
    const params = new URLSearchParams(url.search)
    return {
      utm_source: params.get('utm_source'),
      utm_campaign: params.get('utm_campaign'),
      utm_medium: params.get('utm_medium')
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)
    return defaultValue
  }
}

export const stripHtmlTags = (string: string) => {
  return string.replace(/<[^>]*>?/gm, '')
}

export const arrayToObject = (
  items: { [key: string]: unknown }[],
  key: string,
  value: string
): { [key: string]: unknown } => {
  const object = items.reduce((acc, curr) => {
    if (!(typeof curr[key] === 'number' || typeof curr[key] === 'string'))
      throw new Error('String / number can only be used to index')

    acc[curr[key] as string] = curr[value]
    return acc
  }, {})

  return object
}

export const contentTypeKeyToCamelCase = (
  obj: Record<string, unknown>
): Record<string, unknown> => {
  const updatedObj: Record<string, unknown> = {
    ...obj,
    ['contentType']: obj.content_type
  }
  delete updatedObj.content_type
  return updatedObj
}

/**
 * This function calculates the reading time of a blog article
 * @param str
 * @returns number
 */

export const contentTypes = [
  { name: 'Blogs and Articles', value: 'Blogs and Articles', type: 'text' },
  { name: 'Website Content', value: 'Website Content', type: 'text' },
  { name: 'Product Descriptions', value: 'Product Descriptions', type: 'text' },
  { name: 'Social Media Content', value: 'Social Media Content', type: 'text' },
  { name: 'Press Releases', value: 'Press Releases', type: 'text' },
  { name: 'Book Writing', value: 'Book Writing', type: 'text' },
  { name: 'E-Books', value: 'E-Books', type: 'text' },
  { name: 'Newsletters', value: 'Newsletters', type: 'text' },
  { name: 'Whitepapers', value: 'Whitepapers', type: 'text' },
  { name: 'Emailers', value: 'Emailers', type: 'text' },
  { name: 'Other', value: 'Other', type: 'text' },
  { name: 'Posters', value: 'Posters', type: 'design' },
  { name: 'Infographics', value: 'Infographics', type: 'design' },
  { name: 'Ad-Banners', value: 'Ad-Banners', type: 'design' },
  { name: 'Website Design', value: 'Website Design', type: 'design' },
  { name: 'Email Design', value: 'Email Design', type: 'design' },
  { name: 'Blog Images', value: 'Blog Images', type: 'design' },
  { name: 'Logo Design', value: 'Logo Design', type: 'design' },
  { name: 'Whitepaper Design', value: 'Whitepaper Design', type: 'design' },
  { name: 'E-Book Design', value: 'E-Book Design', type: 'design' },
  { name: 'Blog Translation', value: 'Blog Translation', type: 'vernacular' },
  {
    name: 'Website Translation',
    value: 'Website Translation',
    type: 'vernacular'
  },
  { name: 'App Translation', value: 'App Translation', type: 'vernacular' },
  { name: 'Subtitling', value: 'Subtitling', type: 'vernacular' },
  {
    name: 'Language Content Creation',
    value: 'Language Content Creation',
    type: 'vernacular'
  },
  { name: 'Explainer Videos', value: 'Explainer Videos', type: 'videos' },
  { name: 'Production Films', value: 'Production Films', type: 'videos' }
]

export const getFormatRgbaColor = (color: string) => {
  const formatColor = color.replace(/_/, '(').replace(/_/g, ',') + ')'
  return formatColor
}

export function sliceArrayIntoChunks(arr: unknown[], chunkSize: number) {
  const response: unknown[] = []
  arr.forEach((_, i) => {
    if (i % chunkSize === 0) {
      const chunk = arr.slice(i, i + chunkSize)
      response.push(chunk)
    }
  })
  return response
}

export function insertItemToArray<T>(
  array: T[],
  index: number,
  ...newItems: T[]
): T[] {
  return [...array.slice(0, index), ...newItems, ...array.slice(index)]
}

export const mmddyyyyDateFormat = (date: string) => {
  const dateObj = new Date(date)

  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: '2-digit',
    year: 'numeric'
  }

  return dateObj.toLocaleDateString('en', options)
}

export const calendlyAllowedCountries = countries
  .filter(country => country.metadata.calendlyAllowed)
  .map(country => country.name)

export const eventStartDate = (dateTime: string) => {
  const date = new Date(dateTime)

  const day = date.getDate()
  const month = date.toLocaleString('default', { month: 'long' })
  const year = date.getFullYear()
  const weekday = date.toLocaleString('default', { weekday: 'long' })

  const dayWithSuffix = getDayWithSuffix(day)

  return `${dayWithSuffix} ${month} ${year}, ${weekday}`
}

const SPECIAL_CASE_START = 11
const SPECIAL_CASE_END = 13

export const getDayWithSuffix = (day: number) => {
  if (day >= SPECIAL_CASE_START && day <= SPECIAL_CASE_END) {
    return `${day}th`
  }
  switch (day % 10) {
    case 1:
      return `${day}st`
    case 2:
      return `${day}nd`
    case 3:
      return `${day}rd`
    default:
      return `${day}th`
  }
}

export const calculateDurationInMinutes = (start: Date, end: Date): number => {
  const durationInMilliseconds = end.getTime() - start.getTime()
  return Math.floor(durationInMilliseconds / (1000 * 60))
}

export const convertTo12HourTime = (dateTimeString: string): string => {
  const date = new Date(dateTimeString)
  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  })
  return formattedTime
}

export const fetchTimeZone = (
  zoneName:
    | 'long'
    | 'short'
    | 'shortOffset'
    | 'longOffset'
    | 'shortGeneric'
    | 'longGeneric' = 'long',
  date: string
): string => {
  const formatter = new Intl.DateTimeFormat(undefined, {
    timeZoneName: zoneName
  })
  const startDate = new Date(date)
  // run formatter on the current date
  const formattedParts = formatter.formatToParts(startDate)
  // extract the actual value from the formatter, only reliable way I can find to do this
  const timeZonePart = formattedParts.find(
    formatted => formatted.type === 'timeZoneName'
  )
  if (timeZonePart) {
    return timeZonePart.value
  } else {
    throw new Error('Time zone part not found.')
  }
}

// Split the string by hyphen and capitalize each word
export const capitalizeAndReplace = (inputString: string): string => {
  const words: string[] = inputString
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))

  // Join the words to form the final string
  const result: string = words.join(' ')

  return result
}

export const getUTMQueryParams = (): Nullable<UTMParams> => {
  const params = new URLSearchParams(window.location.search)
  const utmParams = {
    utm_source: params.get('utm_source'),
    utm_medium: params.get('utm_medium'),
    utm_campaign: params.get('utm_campaign')
  }
  return utmParams
}
