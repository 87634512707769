import { useState, useEffect } from 'react'
import clsx from 'clsx'
import Image from 'next/image'

/* blocks */
import Link from '@/atoms/Link/Link'
import YoutubePlayer from '@/atoms/YoutubePlayer/YoutubePlayer'

/* utils */
import { stripHtmlTags } from '@/utils/functions/utils'

/* styling */
import styles from './_.module.css'

const INTERVAL_DURATION = 1500

interface Props {
  class?: string
  primaryHeadline: string
  heroTitleTag?: string
  subText: string
  primaryCta: StrapiLinkSharedComponent
  secondaryCta?: StrapiLinkSharedComponent | null
  footNote?: string | null
  image?: {
    url: string
    alternativeText?: string
    mime: string
    height: number
    width: number
  }
  layout?: 'center' | 'left'
  videoLink?: string | null
  mode?: 'light' | 'dark'
}

function PageHero({
  layout = 'center',
  mode = 'dark',
  ...props
}: Props): JSX.Element {
  return (
    <section
      className={clsx(
        props.class,
        mode === 'dark'
          ? 'bg-blue-web-90 text-white'
          : 'bg-white text-blue-web-90'
      )}
    >
      {layout === 'center' ? (
        <CenterLayout mode={mode} {...props} />
      ) : (
        <LeftLayout mode={mode} {...props} />
      )}
    </section>
  )
}

function CenterLayout({
  primaryHeadline,
  subText,
  footNote,
  primaryCta,
  secondaryCta,
  videoLink,
  image,
  mode
}: Props): JSX.Element {
  const stringWithoutBracketContents = primaryHeadline.replace(/\[.*?\]/g, '')
  const startIndex = primaryHeadline.indexOf('[') + 1
  const endIndex = primaryHeadline.indexOf(']')
  const arrayString = primaryHeadline.substring(startIndex, endIndex)
  const words = arrayString
    .split(/(<\/span>),\s/)
    .map(item => item.trim())
    .map(item => item.replace('</span>', ''))
    .filter(item => item !== '')

  const [currentWordIndex, setCurrentWordIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex(prevIndex => (prevIndex + 1) % words.length)
    }, INTERVAL_DURATION)

    return () => clearInterval(interval)
  }, [words])

  const renderedTitle = `${
    stringWithoutBracketContents.slice(0, startIndex - 1) +
    `<br />` +
    words[currentWordIndex] +
    stringWithoutBracketContents.slice(startIndex - 1)
  }`

  return (
    <div
      className={clsx(
        'container | font-heading | md:text-center | py-10 md:py-18'
      )}
    >
      {primaryHeadline.includes('[') && primaryHeadline.includes(']') ? (
        <h1
          className={clsx(
            'title animate max-w-col-10 mx-auto | text-4xl lg:text-6xl font-medium | mb-4',
            styles.title
          )}
          dangerouslySetInnerHTML={{ __html: renderedTitle }}
        />
      ) : (
        <h1
          className={clsx(
            'title max-w-col-10 mx-auto | text-4xl lg:text-6xl font-medium | relative mb-4',
            styles.title
          )}
          dangerouslySetInnerHTML={{ __html: primaryHeadline }}
        />
      )}
      {subText ? (
        <div
          className="font-body font-normal text-lg lg:text-xl | max-w-col-8 mx-auto mb-10"
          dangerouslySetInnerHTML={{ __html: subText }}
        />
      ) : null}
      <div
        className={clsx(
          'font-body font-medium | flex flex-col md:flex-row justify-center gap-4',
          stripHtmlTags(footNote ?? '') ? 'mb-6' : 'mb-10'
        )}
      >
        <Link href={primaryCta.href} className="new-btn-primary | font-medium">
          {primaryCta.title}
        </Link>
        {secondaryCta && (
          <Link
            href={secondaryCta.href}
            className={clsx(
              mode === 'dark' ? 'new-btn-secondary' : 'new-btn-blue-outline',
              'font-bold'
            )}
          >
            {secondaryCta.title}
          </Link>
        )}
      </div>
      {stripHtmlTags(footNote ?? '') ? (
        <div
          className="font-body font-medium text-sm | max-w-col-8 | mb-10 mx-auto"
          dangerouslySetInnerHTML={{ __html: footNote ?? '' }}
        />
      ) : null}

      {videoLink || image ? (
        image ? (
          image.mime === 'video/mp4' ? (
            <video
              width="320"
              height="240"
              className="rounded-xl block w-full"
              src={image.url}
              autoPlay
              muted
              loop
              preload="true"
            ></video>
          ) : (
            <Image
              src={image.url}
              alt={image.alternativeText ?? ''}
              width={image.width}
              height={image.height}
              className="rounded-2xl | max-w-5xl w-full | mx-auto"
              priority
            />
          )
        ) : (
          <YoutubePlayer
            className="block | max-w-5xl w-full | aspect-video | mx-auto p-0"
            videoId={videoLink?.split('/embed/')[1]}
            posterLoading="eager"
          />
        )
      ) : null}
    </div>
  )
}

function LeftLayout({
  primaryHeadline,
  heroTitleTag,
  subText,
  footNote,
  primaryCta,
  secondaryCta,
  image,
  mode
}: Props): JSX.Element {
  return (
    <div className="container | font-heading | text-left | py-10 md:py-18">
      <div className="grid grid-cols-1 md:grid-cols-12 gap-8 | items-center">
        <div className="md:col-span-5">
          {heroTitleTag ? (
            <span
              className="block | text-base md:text-xl font-bold uppercase | mb-6 md:mb-5"
              dangerouslySetInnerHTML={{ __html: heroTitleTag }}
            />
          ) : null}
          {primaryHeadline ? (
            <h1
              className="title | text-4xl lg:text-6xl font-medium tracking-tight | mb-4"
              dangerouslySetInnerHTML={{ __html: primaryHeadline }}
            />
          ) : null}
          {subText ? (
            <h2
              className={clsx(
                'font-body text-lg lg:text-xl | mb-10 lg:mb-12',
                styles.description
              )}
              dangerouslySetInnerHTML={{ __html: subText }}
            />
          ) : null}
          <div
            className={clsx(
              'font-body font-medium | flex flex-col sm:flex-row justify-center md:justify-start gap-4 | w-full',
              stripHtmlTags(footNote ?? '') ? 'mb-6' : ''
            )}
          >
            <Link
              href={primaryCta.href}
              className={clsx(
                'new-btn-primary | font-medium',
                !secondaryCta?.href ? 'flex-grow max-w-[351px]' : ''
              )}
            >
              {primaryCta.title}
            </Link>
            {secondaryCta && (
              <Link
                href={secondaryCta.href}
                className={clsx(
                  mode === 'dark'
                    ? 'new-btn-secondary'
                    : 'new-btn-blue-outline',
                  'font-medium'
                )}
              >
                {secondaryCta.title}
              </Link>
            )}
          </div>
          {stripHtmlTags(footNote ?? '') ? (
            <div
              className="font-body font-medium text-sm"
              dangerouslySetInnerHTML={{ __html: footNote ?? '' }}
            />
          ) : null}
        </div>
        <div className="md:col-start-7 md:col-end-13">
          {image && (
            <Image
              src={image.url}
              alt={image.alternativeText ?? ''}
              width={image.width}
              height={image.height}
              className="rounded-2xl | w-full max-w-col-6 | mx-auto"
              priority
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default PageHero
