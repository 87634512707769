import { LiteYTEmbed } from '@justinribeiro/lite-youtube'
import { useEffect } from 'react'

interface Props {
  className?: string
  videoId: string | undefined
  posterLoading?: 'lazy' | 'eager'
  // https://github.com/justinribeiro/lite-youtube#youtube-queryparams
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'lite-youtube': Partial<LiteYTEmbed> & { class: string }
    }
  }
}

function YoutubePlayer({
  className,
  videoId,
  posterLoading = 'lazy'
}: Props): JSX.Element | null {
  useEffect(() => {
    import('@justinribeiro/lite-youtube')
  }, [])

  if (!videoId) return null

  return (
    <lite-youtube
      class={className ?? ''}
      videoId={videoId}
      noCookie={true}
      posterQuality="maxresdefault"
      posterLoading={posterLoading}
    />
  )
}

export default YoutubePlayer
